.loader-wrapper {
  width: 100%;
  height: 100%;
  background-color: #333233;
  display:flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  opacity: 0.5;
}
// .loader {
//   display: inline-block;
//   width: 100px;
//   height: 100px;
//   position: relative;
//   border: 4px solid #Fff;
//   animation: loader 3s infinite ease;
// }
// .loader-inner {
//   vertical-align: top;
//   display: inline-block;
//   width: 100%;
//   background-color: rgb(87, 191, 206);
//   animation: loader-inner 3s infinite ease-in;
// }


// @keyframes loader-inner {
//   0% { height: 0%; }
//   25% { height: 0%; }
//   50% { height: 100%; }
//   75% { height: 100%; }
//   100% { height: 0%; }
// }

/* HTML: <div class="loader"></div> */
.loader {
  width: 70px;
  height: 70px;
  background:  rgb(87, 191, 206);
  border-radius: 50px;
  -webkit-mask:
    radial-gradient(circle 31px at 50% calc(100% + 13px), #000 95%, #0000) top 4px left 50%,
    radial-gradient(circle 31px, #000 95%, #0000) center,
    radial-gradient(circle 31px at 50% -13px, #000 95%, #0000) bottom 4px left 50%,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-repeat: no-repeat;
  animation: l10 1.5s infinite;
}

@keyframes l10 {
  0% {
    -webkit-mask-size: 0 18px, 0 18px, 0 18px, auto
  }

  16.67% {
    -webkit-mask-size: 100% 18px, 0 18px, 0 18px, auto
  }

  33.33% {
    -webkit-mask-size: 100% 18px, 100% 18px, 0 18px, auto
  }

  50% {
    -webkit-mask-size: 100% 18px, 100% 18px, 100% 18px, auto
  }

  66.67% {
    -webkit-mask-size: 0 18px, 100% 18px, 100% 18px, auto
  }

  83.33% {
    -webkit-mask-size: 0 18px, 0 18px, 100% 18px, auto
  }

  100% {
    -webkit-mask-size: 0 18px, 0 18px, 0 18px, auto
  }
}